<template>
  <div class="editor">
    <div id="aliyun-video-editor"></div>
  </div>
</template>

<script>
import { commitVideoCompile } from '@/utils/serveApi';
import { localStorages } from '@/utils/public';
export default {
  name: 'editor',
  data() {
    return {
      objVideo: JSON.parse(this.$route.query.id),
      loading: null
    };
  },
  created() {
    this.loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
  },
  mounted() {
    setTimeout(() => this.aliyunVideoEditor(), 1000);
    document.addEventListener('visibilitychange', e => {
      if (document.visibilityState == 'hidden') this.clickHref();
    });
  },
  methods: {
    aliyunVideoEditor() {
      this.loading.close();
      window.AliyunVideoEditor.init({
        locale: 'zh-CN',
        container: document.getElementById('aliyun-video-editor'),
        getEditingProject: () => Promise.resolve({}),
        useDynamicSrc: true,
        getDynamicSrc: (mediaId, mediaType) => {
          return new Promise(resolve => {
            setTimeout(() => {
              let url = '';
              if (mediaType === 'video') {
                url = this.objVideo.url;
                resolve(url);
              } else if (mediaType === 'audio') {
                resolve(url);
              } else {
                resolve(url);
              }
            }, 100);
          });
        },
        getEditingProjectMaterials: () => {
          return Promise.resolve([this.objVideo]);
        },
        searchMedia: mediaType => {
          if (mediaType === 'video') {
            return Promise.resolve([this.objVideo]);
          } else if (mediaType === 'audio') {
            return Promise.resolve([]);
          } else {
            return Promise.resolve([]);
          }
        },
        deleteEditingProjectMaterials: () => {
          return Promise.resolve();
        },
        getStickerCategories: () =>
          Promise.resolve([
            {
              id: 'sticker-bubble',
              name: '气泡'
            },
            {
              id: 'sticker-daily',
              name: '日常'
            }
          ]),
        getStickers: config => {
          if (config.categoryId === 'sticker-bubble') {
            return Promise.resolve({
              total: 2,
              stickers: [
                {
                  mediaId: '66864e82a7079a2d9625b9f618ecdc18',
                  src: 'https://img.alicdn.com/imgextra/i2/O1CN017kWsap1mkqDIpmOmB_!!6000000004993-2-tps-200-200.png'
                },
                {
                  mediaId: '37363a989115dd5b02a00c80f15f799a',
                  src: 'https://img.alicdn.com/imgextra/i3/O1CN013j445M1FxJgPd5KJN_!!6000000000553-2-tps-200-200.png'
                }
              ]
            });
          }
        },
        updateEditingProject: ({ coverUrl, duration, timeline, isAuto }) =>
          new Promise((resolve, reject) => {
            if (isAuto) {
              !isAuto && this.$message.success('保存成功');
              resolve();
            } else {
              reject();
            }
          }),
        produceEditingProjectVideo: ({ coverUrl, duration = 0, aspectRatio, timeline, recommend }) => {
          return new Promise((resolve, reject) => {
            const { cropHighVideoMin, cropHighVideoMax } = localStorages('getDataConfig');
            if (duration > cropHighVideoMax || duration < cropHighVideoMin) {
              this.$message.warning(`该视频超出限制,高光截取必须在${cropHighVideoMin}-${cropHighVideoMax}秒之间`);
              resolve();
              return;
            }
            const { In, Out, MediaId } = timeline.VideoTracks[0].VideoTrackClips[0];
            commitVideoCompile({ inTime: In, outTime: Out, mediaId: MediaId }).then(res => {
              if (res.success) {
                this.$message.success('高光视频上传成功');
                setTimeout(() => this.clickHref(), 2000);
              }
            });
          });
        }
      });
    },
    clickHref() {
      window.location.href = 'about:blank';
      window.close();
    }
  },
  beforeDestroy() {
    this.reload();
  }
};
</script>
<style lang="scss">
.HeaderToolBar_save_button__2Qzba {
  display: none;
}
.HeaderToolBar_header_tool_bar__2uvXn > button:first-of-type {
  display: none;
}
</style>
<style lang="scss" scoped>
@import 'https://g.alicdn.com/thor-server/video-editing-websdk/3.9.0/index.css';
body {
  overflow: hidden;
  .editor {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    #aliyun-video-editor {
      width: 100vw;
      height: 100vh;
    }
  }
}
</style>
